<template>
  <div class="tutorial-vpn">
    <p
      v-for="title in pageTitle"
      :key="title"
      class="page-title"
      v-text="title"
    ></p>
    <div class="rows">
      <video ref="video" controls loop playsinline>
        <source src="./assets/video.mp4" type="video/mp4" />
      </video>
      <img v-src="require('./assets/01.png')" alt="" />
      <p>
        当您的页面出现以下画面，即是代表您所在的地区限制无法进行游戏，此时只需要经由VPN变更您的所在国家，即可快速开始游戏。
      </p>
      <img v-src="require('./assets/02.png')" alt="" class="snap" />
      <p class="title">什么是VPN?</p>
      <p>
        VPN
        也就是「虚拟私人网路」，能够保护您的网路连线和线上身份，为您的资料建立加密通道，隐藏IP
        位址。甚至在使用公用Wi-Fi
        热点，VPN透过加密您的资料来保护您的活动，让您安心浏览存取个人资料。对于经常游戏的玩家，VPN
        便能帮您打破地区限制，变更您的ＩＰ位置，随时随地开始游戏。
      </p>
      <img v-src="require('./assets/03.png')" alt="" />
      <p class="ttile">开始使用VPN - Outline</p>
      <p class="subtitle">
        1. 首先请进入<a href="https://getoutline.org/zh-CN/">Outline</a
      >官方平台首页，点击<span class="red">获取OUTLINE</span>
      </p>
      <img v-src="require('./assets/04.png')" alt="" class="snap" />
      <p class="subtitle">
        2. 往下滑至页尾，点击<span class="red">获取OUTLINE客户端</span
      >，便会自动跳转至下载点
      </p>
      <img v-src="require('./assets/05.png')" alt="" class="snap" />
      <p class="subtitle">3. 将此VPN软件下载至手机</p>
      <img v-src="require('./assets/06.png')" alt="" class="snap" />
      <p class="subtitle">4.回到提示页面，复制密钥</p>
      <p class="key">
        ss://Y2hhY2hhMjAtaWV0Zi1wb2x5MTMwNTpuYVNpVWNMSHZXVFo=@45.76.104.147:13195/?outline=1
      </p>
      <img v-src="require('./assets/07.png')" alt="" class="snap" />
      <p class="subtitle">5. 下载完成后，将此VPN软件开启</p>
      <img v-src="require('./assets/08.png')" alt="" class="snap" />
      <p class="subtitle">6. 点击我知道了，进入下一个页面</p>
      <img v-src="require('./assets/09.png')" alt="" class="snap" />
      <p class="subtitle">
        7. 出现此页面，即可直接点击<span class="red">添加服务器</span>
        (直接看第<span class="green">11</span>个步骤)
      </p>
      <img v-src="require('./assets/10.png')" alt="" class="snap" />
      <p class="subtitle">8. 如出现下图页面，请点击右上角＋添加服务器</p>
      <img v-src="require('./assets/11.png')" alt="" class="snap" />
      <p class="subtitle">9. 回到提示页面，复制密钥</p>
      <p class="key">
        ss://Y2hhY2hhMjAtaWV0Zi1wb2x5MTMwNTpuYVNpVWNMSHZXVFo=@45.76.104.147:13195/?outline=1
      </p>
      <img v-src="require('./assets/12.png')" alt="" class="snap" />
      <p class="subtitle">10 . 久按出现Paste，即可将已复制的密钥贴上于栏位</p>
      <img v-src="require('./assets/13.png')" alt="" class="snap" />
      <p class="subtitle text-center">
        点击<span class="red">添加服务器</span>
      </p>
      <img v-src="require('./assets/14.png')" alt="" class="snap" />
      <p class="subtitle">11. 点击<span class="red">连结</span></p>
      <img v-src="require('./assets/15.png')" alt="" class="snap" />
      <p class="subtitle">12. 出现提示，点击<span class="red">允许</span></p>
      <img v-src="require('./assets/16.png')" alt="" class="snap" />
      <p class="subtitle">13. 验证设定（输入您手机的设定密码）</p>
      <img v-src="require('./assets/17.png')" alt="" class="snap" />
      <p class="subtitle">14. 成功连结后会自动跳转，出现以下画面</p>
      <img v-src="require('./assets/18.png')" alt="" class="snap" />
      <p class="subtitle">
        当你完成以上流程，就可以重新进入我们的游戏平台，开始游戏啰！
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tutorial-vpn',
  data() {
    return {
      title: ['VPN下载', 'Outline 软件教程']
    }
  },
  computed: {
    pageTitle() {
      return this.$store.state.isMobileLayout
        ? this.title
        : [this.title.join(' - ')]
    }
  },
  mounted() {
    try {
      this.$refs.video.play()
    } catch {
    }
  }
}
</script>

<style lang="scss" scoped>
.tutorial-vpn {
  p {
    &.key {
      color: #d5b157;
      word-break: break-all;
    }
  }
}
</style>
